/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { FaInstagram, FaBandcamp } from "react-icons/fa"
import styled from "styled-components"

import NavBar from "./NavBar"
import Title from "./Title"
import Content from "./Content"
import "./layoutstyle.css"

const Footer = styled.footer`
  padding-top: 1rem;
  margin-top: 1rem;
  border-top: 1px solid white;
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const FooterSection = styled.div`
  margin-bottom: 1rem;
`
const FooterSectionLeft = styled(FooterSection)`
  @media (max-width: 428px) {
    flex-basis: 100%;
  }
`

const FooterSectionRight = styled(FooterSection)``

const Flex = styled.div`
  display: flex;
  flex-grow: 2;
  justify-content: space-between;
  align-items: center;
`

const FlexLink = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const FlexMarginLeft = styled(Flex)`
  margin-left: 0.5em;
`

const FlexLinkMarginLeft = styled(FlexLink)`
  margin-left: 1em;
`

const Layout = ({ children }) => {
  return (
    <>
      <NavBar />
      <Title />
      <Content>
        <main>{children}</main>
      </Content>
      <Footer>
        <FooterSectionLeft>
          <Flex>
            <FlexLink href="https://johannmcbee.bandcamp.com/releases">
              <FaBandcamp />
              <FlexMarginLeft>Bandcamp</FlexMarginLeft>
            </FlexLink>
            <FlexLinkMarginLeft href="https://www.instagram.com/johannmcbee/">
              <FaInstagram />
              <FlexMarginLeft>Instagram</FlexMarginLeft>
            </FlexLinkMarginLeft>
          </Flex>
        </FooterSectionLeft>
        <FooterSectionRight>
          © Johann McBee {new Date().getFullYear()}
        </FooterSectionRight>
      </Footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
