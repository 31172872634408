import { useStaticQuery, graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const TitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`

const Text = styled.h1`
  font-size: 2em;
  margin: 0;
  color: var(--red);
  letter-spacing: 0.2em;
  position: relative;
`

const Header = () => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <Link to="/">
      <TitleContainer>
        <Text>{data.site.siteMetadata.title}</Text>
      </TitleContainer>
    </Link>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
