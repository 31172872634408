import { Link } from "gatsby"
import styled from "styled-components"

const StyledLink = styled(Link)`
  &:hover {
    box-shadow: 0px 3px 0px 0px var(--red);
  }
`

export default StyledLink
