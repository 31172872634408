import React from "react"
import styled from "styled-components"
import StyledLink from "./StyledLink"

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0 2rem 0;
`

const ResponsiveStyledLink = styled(StyledLink)`
  @media (max-width: 568px) {
    display: none;
  }
`

const ResponsiveSpan = styled.span`
  @media (max-width: 568px) {
    display: none;
  }
`

const NavBar = () => {
  return (
    <Container>
      <ResponsiveStyledLink to="/">Home</ResponsiveStyledLink>
      <StyledLink to="/music">Music</StyledLink>
      <StyledLink to="/music-therapy">
        <ResponsiveSpan>Music </ResponsiveSpan>Therapy
      </StyledLink>
      <StyledLink to="/bio">Bio</StyledLink>
      <StyledLink to="/contact">Contact</StyledLink>
    </Container>
  )
}

export default NavBar
